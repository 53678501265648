import React from 'react';

const Block6 = () => {
    return (
        <section id="contacts" className="block6">
            <div className="block6__container">
                <div className="block6__title__container">
                    <h3 className="block6__title">contacts</h3>
                </div>
                <div className="block6__text__container">
                    <p className="block6__text block6__text__uppercase">Chemineralstrade OÜ</p>
                    <p className="block6__text block6__text__uppercase">Harju country, Tallinn, Kesklinna district, </p>
                    <p className="block6__text block6__text__uppercase">Tartu mnt 25, 10117, Estonia</p>
                    <p className="block6__text block6__text__margin">info@chemineralstrade.com</p>
                    {/*<p className="block6__text">Oleg Rashkovskyi</p>*/}
                    {/*<p className="block6__text">Board member</p>*/}
                </div>
            </div>
        </section>
    );
};

export default Block6;
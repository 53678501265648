import React from 'react';
import TypeAnimation from 'react-type-animation';
import {useInView} from "react-intersection-observer";


const Block5 = () => {
    const {ref, inView} = useInView({
        threshold: 0,
        triggerOnce:true,
    });
    return (
        <section className="block5">
            <div className="block5__container">
                <div ref={ref} className="block5__title__container">
                    {inView  ?
                        <>
                            <TypeAnimation
                                cursor={false}
                                className={"block5__title"}
                                sequence={[
                                    1000,
                                    'we work',
                                ]}
                                wrapper="h3"
                                repeat={1}
                            />
                            <TypeAnimation
                                cursor={false}
                                className={"block5__title"}
                                sequence={[
                                    2000,
                                    'within',
                                ]}
                                wrapper="h3"
                                repeat={1}
                            />
                        </>
                        :
                        <>
                            <h3 className="block5__title">we work</h3>
                            <h3 className="block5__title block5__title__border">within</h3>
                        </>
                    }
                    <span className="title__span"/>
                </div>
                <div className="block5__text__container">
                    {inView  ?
                        <>
                            <TypeAnimation
                                cursor={false}
                                className={"block5__text"}
                                sequence={[
                                    3000,
                                    'All European',

                                ]}
                                wrapper="p"
                                repeat={1}
                            />
                            <TypeAnimation
                                cursor={false}
                                className={"block5__text"}
                                sequence={[
                                    4000,
                                    'countries',
                                ]}
                                wrapper="p"
                                repeat={1}
                            />
                        </>
                        :
                        <>
                            <p className="block5__text">All European</p>
                            <p className="block5__text">countries</p>
                        </>
                    }
                </div>
            </div>
        </section>
    );
};

export default Block5;
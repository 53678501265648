import React from 'react';
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import icon1 from "../img/block2/delivery.png";
import icon2 from "../img/block2/widrRange.png";
import icon3 from "../img/block2/standards.png";
import icon4 from "../img/block2/employees.png";
import icon5 from "../img/block2/quarantee.png";



const Block2 = () => {
    const { ref,inView } = useInView({
        threshold: 0,
        triggerOnce:true,
    });
    return (
        <section id="aboutUs" className="block2">
            <div className="block2__container">
                <div className="block2__text__title__wrapper">
                    <div className="block2__text__top__container">
                        <p className="block2__text__top"><span className="block2__text__top__capital">Chemineralstrade OÜ</span> is
                            a reliable supplier</p>
                        <p className="block2__text__top">of mineral fertilizers at the European market that specializes
                            in selling products produced in:</p>
                    </div>
                    <div className="block2__title__container">
                        <h3 className="block2__title">ABOUT US</h3>
                    </div>
                </div>
                <div ref={ref} className="block2__list__wrapper">
                    {/*{inView ?*/}
                        <motion.div
                            className="about__text__container"
                            initial={{x: -1000}}
                            animate={{x:inView ? 0 : -1000}}
                            transition={{type: "spring", duration: 1.5}}
                        >
                            <ul className="block2__list__container1">
                                <li className="block2__list">Uzbekistan;</li>
                                <li className="block2__list">Turkmenistan;</li>
                                <li className="block2__list"> Jordan;</li>
                            </ul>
                        </motion.div>
                    {/*    :*/}
                    {/*    <div className="about__text__container">*/}
                    {/*    <ul className="block2__list__container1">*/}
                    {/*    <li className="block2__list">Uzbekistan;</li>*/}
                    {/*    <li className="block2__list">Turkmenistan;</li>*/}
                    {/*    <li className="block2__list"> Jordan;</li>*/}
                    {/*    </ul>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {/*{inView ?*/}
                        <motion.div
                            className="about__text__container"
                            initial={{x: 1000 }}
                            animate={{x: inView ?  0 : 1000}}
                            transition={{type: "spring", duration: 1.5}}
                        >
                            <ul className="block2__list__container2">
                                <li className="block2__list">Azerbaijan;</li>
                                <li className="block2__list">Europe;</li>
                                <li className="block2__list">and others</li>
                            </ul>
                        </motion.div>
                    {/*    :*/}
                    {/*    <div className="about__text__container">*/}
                    {/*        <ul className="block2__list__container2">*/}
                    {/*            <li className="block2__list">Azerbaijan;</li>*/}
                    {/*            <li className="block2__list">Europe;</li>*/}
                    {/*            <li className="block2__list">and others</li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
                <ul className="block2__list__icon__container">
                    <li className="block2__list__icon">
                        <img className="list__icon__img" src={icon1} alt="icon Fast delivery"/>
                        <p className="list__icon__text"><span
                            className="list__icon__text__bold">Fast delivery</span></p>
                        <p className="list__icon__text">and 24/7 support</p>
                    </li>
                    <li className="list__icon">
                        <img className="list__icon__img" src={icon2} alt="icon wide range"/>
                        <p className="list__icon__text">A wide range</p>
                        <p className="list__icon__text">at <span
                            className="list__icon__text__bold">favorable prices</span></p>
                    </li>
                    <li className="list__icon">
                        <img className="list__icon__img" src={icon3} alt="icon High standards"/>
                        <p className="list__icon__text"><span
                            className="list__icon__text__bold">High standards</span> of</p>
                        <p className="list__icon__text">production by manufactures</p>
                    </li>
                    <li className="list__icon">
                        <img className="list__icon__img" src={icon4} alt="icon professionals"/>
                        <p className="list__icon__text">Our employees are</p>
                        <p className="list__icon__text"><span
                            className="list__icon__text__bold">professionals</span></p>
                    </li>
                    <li className="list__icon">
                        <img className="list__icon__img" src={icon5} alt="icon Guarantee"/>
                        <p className="list__icon__text"><span className="list__icon__text__bold">Guarantee</span> a
                        </p>
                        <p className="list__icon__text">profitable cooperation</p>
                    </li>
                </ul>
                <div className="block2__text__bottom__container ">
                    <p className="block2__text__bottom">Cooperation with us is reliable and very comfortable for our
                        clients!</p>
                </div>
            </div>
        </section>
    );
};

export default Block2;
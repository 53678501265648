import React from 'react';
import logo from '../img/logoHeader.png';


const SideBar = () => {
    return (
        <div className="sidebar">
            <div className="sidebar__left__container">
                <a className="link__anchor" href="#ourProducts">our products</a>
                <a className="link__anchor" href="#packing">packing</a>
            </div>
            <img className="sidebar__img" src={logo} alt="logotype cheminerals trade OÜ"/>
            <div className="sidebar__right__container">
                <a className="link__anchor" href="#aboutUs">about us</a>
                <a className="link__anchor" href="#contacts">contacts</a>
            </div>
        </div>
    );
};

export default SideBar;
import React from 'react';
import logo from '../img/logoFooter.png';

const Footer = () => {
    return (
        <footer className="footer">
            <img className="footer__img" src={logo} alt="logotype cheminerals trade OÜ"/>
        </footer>
    );
};

export default Footer;
import React from 'react';
import Main from "./Main";
import Footer from "./Footer";

const App = () => {
    return (
        <>
            <Main/>
            <Footer/>
        </>
    );
}

export default App;

import React from 'react';
import SideBar from "./sideBar";
import { motion } from "framer-motion"

const Block1 = () => {
    return (
        <section className="block1">
            <SideBar/>
            <div className="block1__container">
                <motion.div
                    className="about__text__container"
                    initial={{y: -1600}}
                    animate={{y: 0}}
                    transition={{type: "spring", duration: 1.5}}
                >
                <h1 className="block1__title">Chemineralstrade OÜ </h1>
                </motion.div>
                <div className="block1__text__container">
                    <p className="block1__text">The 21st century is the century of progress and new discoveries.</p>
                    <p className="block1__text block1__text__margin">
                        Going with time, with each person the increasing use of opportunities in their field
                        of activity. Here flowers, gardeners and farmers are also trying to keep up, bringing
                        to responsibility for the work of various types of harmful substances and
                        stimulants.</p>
                </div>
            </div>
        </section>
    );
};

export default Block1;
import React from "react";
import ammonium from "../img/circleHover/ammoniumNitrate.png";
import urea from "../img/circleHover/urena.png";
import potassium from "../img/circleHover/potassiumChloride.png";
import ammoniumSulfate from "../img/circleHover/ammoniumSulfate.png";
import sulfur from "../img/circleHover/sulfur.png";

const Block3 = () => {
  return (
    <section id="ourProducts" className="block3">
      <div className="block3__title__container">
        <h3 className="block3__title">WE OFFER THE FOLLOWING PRODUCTS:</h3>
        <span className="block3__title__border"/>
      </div>
      <div className="block3__list__container">
        <div className="block3__list">
          <img
            className="block3__list__img"
            src={ammonium}
            alt="ammonium nitrate"
          />
          <h3 className="block3__list__title">ammonium nitrate</h3>
          <p className="block3__list__text">
            <span className="block3__subtitle__bold">Ammonium nitrate </span> is
            a universal fertilizer that is suitable for almost all plants in
            agriculture. The use of ammonium nitrate stimulates plant growth,
            increases crop yields and resistance to fungal diseases.
          </p>
        </div>
        <div className="block3__list">
          <img className="block3__list__img" src={urea} alt="urea" />
          <h3 className="block3__list__title">urea</h3>
          <p className="block3__list__text">
            <span className="block3__subtitle__bold">Urea</span> is used in
            agriculture for all kinds of crops. The use of urea for grain crops
            is very important because significantly increases the protein
            content of ripe grain. The use of urea in agriculture can
            significantly increase the yield of crops, improve their taste and
            nutrient content in ripe products, as well as prevent diseases and
            pests.
          </p>
        </div>
        <div className="block3__list">
          <img
            className="block3__list__img"
            src={potassium}
            alt="potassium chloride"
          />
          <h3 className="block3__list__title">potassium chloride</h3>
          <p className="block3__list__text">
            <span className="block3__subtitle__bold">Potassium chloride </span>
            is a very important fertilizer in agriculture. Widespread use of
            potassium chloride is possible on all soils, and is very effective
            for root and fruit crops. Potassium fertilizers provide minerals for
            plant nutrition, increase the immunity of crops to diseases, improve
            the taste and appearance of fruits, as well as product storage.
          </p>
        </div>
        <div className="block3__list ammoniumSulfate">
          <img
            className="block3__list__img"
            src={ammoniumSulfate}
            alt="ammonium sulfate"
          />
          <h3 className="block3__list__title">ammonium sulfate</h3>
          <p className="block3__list__text">
            <span className="block3__subtitle__bold"> Ammonium sulfate </span>{" "}
            is an effective, widespread product that is used for industrial
            purposes, as a fertilizer for plants and many types of cereal crops
            in agriculture. Ammonium sulfate prevents accumulation of nitrates,
            reduces the content of radionuclides in plants. It can be used in
            contaminated areas.
            <span className="block3__text__bold">
              Our company has experience in potassium granulation.
            </span>
          </p>
        </div>
        <div className="block3__list sulfur">
          <img className="block3__list__img" src={sulfur} alt="sulfur" />
          <h3 className="block3__list__title ">sulfur</h3>
          <p className="block3__list__text">
            <span className="block3__subtitle__bold">Sulfur</span> is an
            inorganic substance that is used in various fields, including
            agriculture.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Block3;

import React from 'react';
import Block3 from "./Block3";
import Block1 from "./Block1";
import Block2 from "./Block2";
import Block4 from "./Block4";
import Block5 from "./Block5";
import Block6 from "./Block6";



const Main = () => {
    return (
            <main className="main">
                    <Block1/>
                    <Block2/>
                    <Block3/>
                    <Block4/>
                    <Block5/>
                    <Block6/>
            </main>
    );
};

export default Main;